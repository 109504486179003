// src/config.js
const config = {
  API: {
    // Set this to the backend URL
    BASE_URL: 'https://shortify-backend-dot-rice-comp-539-spring-2022.uk.r.appspot.com',
    ENDPOINTS: {
      SHORTEN: '/api/url/shorten',
      SHORTEN_BULK: '/api/url/inbulk',
      AUTH: {
        LOGIN: '/api/auth/login',
        REGISTER: '/api/auth/register'
      }
    }
  },
  STORAGE_KEYS: {
    AUTH_TOKEN: 'auth_token',
    USER_INFO: 'user_info'
  },
  DEFAULTS: {
    USERNAME: 'testuser',
    TTL_DAYS: 7,
    MAX_TTL_DAYS: 14
  }
};

export default config;