// src/components/Sidebar.jsx
import React, { useState } from 'react';

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const menuItems = [
    { icon: '🏠', label: 'Home', action: () => {} },
    { icon: '📊', label: 'Statistics', action: () => {} },
  ];

  return (
    <div
      className={`fixed left-0 top-0 h-full bg-white border-r border-gray-200 transition-all duration-300 ${
        isOpen ? 'w-64' : 'w-16'
      }`}
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      <nav className="mt-8">
        <ul className="space-y-2">
          {menuItems.map((item) => (
            <li key={item.label}>
              <button
                onClick={item.action}
                className={`w-full flex items-center px-4 py-3 text-gray-700 hover:bg-gray-50 transition-colors ${
                  isOpen ? 'justify-start space-x-3' : 'justify-center'
                }`}
              >
                <span className="text-xl">{item.icon}</span>
                {isOpen && (
                  <span className="text-sm font-medium ml-3">{item.label}</span>
                )}
              </button>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;