// src/pages/HomePage.jsx
import React, { useState } from 'react';
import Sidebar from '../components/Sidebar';
import AuthButtons from '../components/AuthButtons';
import apiService from '../services/api';
// import config from '../config';

const HomePage = () => {
  const [originalUrl, setOriginalUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [results, setResults] = useState([]);

  const handleShorten = async () => {
    if (!originalUrl) {
      setError('Please enter URLs to shorten');
      return;
    }

    setLoading(true);
    setError('');

    try {
      const urls = originalUrl.split('\n')
        .map(url => url.trim())
        .filter(url => url);
        
      let results;
      
      if (urls.length === 1) {
        console.log('Making single URL request for:', urls[0]);
        const shortened = await apiService.url.shorten(urls[0]);
        console.log('Received shortened URL:', shortened);
        results = [{
          original: urls[0],
          shortened: shortened,
          timestamp: new Date().toLocaleString()
        }];
      } else {
        // Bulk URL processing
        const shortened = await apiService.url.shortenBulk(urls);
        results = urls.map((url, index) => ({
          original: url,
          shortened: shortened[index],
          timestamp: new Date().toLocaleString()
        }));
      }

      setResults(results);
      setOriginalUrl('');
      
    } catch (err) {
      setError(err.message || 'Failed to shorten URL(s)');
    } finally {
      setLoading(false);
    }
  };

  // Handle copying to clipboard
  const handleCopy = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        // Show temporary success message near the copied item
        setResults(prevResults => 
          prevResults.map(result => 
            result.shortened === text 
              ? { ...result, copyStatus: 'Copied!' }
              : result
          )
        );
        
        // Reset copy status after 2 seconds
        setTimeout(() => {
          setResults(prevResults => 
            prevResults.map(result => 
              result.shortened === text 
                ? { ...result, copyStatus: null }
                : result
            )
          );
        }, 2000);
      })
      .catch(() => {
        setError('Failed to copy to clipboard');
      });
  };

  return (
    <div className="min-h-screen bg-gray-50 flex">
      <Sidebar />
      <AuthButtons />
      
      <main className="flex-1 ml-16 flex flex-col items-center justify-center min-h-screen">
        <div className="w-full max-w-3xl px-4 -mt-20">
          {/* Logo */}
          <h1 className="text-6xl font-bold text-center text-gray-700 mb-16 drop-shadow-md">
            Shortify
          </h1>
          
          {/* Error display */}
          {error && (
            <div className="mb-4 p-3 bg-red-50 border border-red-200 text-red-600 rounded-md">
              {error}
            </div>
          )}
          
          {/* URL Input section */}
          <div className="space-y-2">
            <textarea
              placeholder="Enter URLs here (one per line for multiple URLs)"
              value={originalUrl}
              onChange={(e) => setOriginalUrl(e.target.value)}
              className="w-full p-4 text-lg border border-gray-200 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              rows={5}
              disabled={loading}
            />
            <p className="text-sm text-gray-500 italic">
              Tip: For multiple URLs, put each URL on a new line
            </p>
            <button 
              onClick={handleShorten}
              disabled={loading}
              className={`w-full py-4 ${
                loading 
                  ? 'bg-gray-300 cursor-not-allowed' 
                  : 'bg-blue-500 hover:bg-blue-600'
              } text-white rounded transition-colors`}
            >
              {loading ? 'Processing...' : 'Shorten URL(s)'}
            </button>
          </div>

          {/* Results display */}
          {results.length > 0 && (
            <div className="mt-8 space-y-4">
              <h2 className="text-xl font-semibold text-gray-700">Results:</h2>
              {results.map((result, index) => (
                <div key={index} className="p-4 bg-white rounded-lg border border-gray-200 shadow-sm">
                  <div className="space-y-2">
                    <div>
                      <p className="text-sm text-gray-500">Original URL:</p>
                      <p className="text-gray-700 break-all">{result.original}</p>
                    </div>
                    <div className="flex justify-between items-center">
                      <div className="flex-1 mr-4">
                        <p className="text-sm text-gray-500">Shortened URL:</p>
                        <p className="text-blue-600 break-all">{result.shortened}</p>
                      </div>
                      <button
                        onClick={() => handleCopy(result.shortened)}
                        className={`px-4 py-2 text-sm ${
                          result.copyStatus 
                            ? 'bg-green-50 text-green-600' 
                            : 'text-blue-600 hover:bg-blue-50'
                        } rounded-md transition-colors whitespace-nowrap`}
                      >
                        {result.copyStatus || 'Copy'}
                      </button>
                    </div>
                    <p className="text-xs text-gray-400">{result.timestamp}</p>
                  </div>
                </div>
              ))}
            </div>
          )}

          {/* Info sections */}
          <div className="grid grid-cols-2 gap-8 text-gray-600 mt-16">
            <div>
              <h2 className="text-2xl mb-4">Why choose us?</h2>
              <ul className="space-y-2">
                <li className="flex items-center space-x-2">
                  <span>-</span>
                  <span>Fast and reliable service</span>
                </li>
                <li className="flex items-center space-x-2">
                  <span>-</span>
                  <span>Secure link management</span>
                </li>
                <li className="flex items-center space-x-2">
                  <span>-</span>
                  <span>Easy to use interface</span>
                </li>
              </ul>
            </div>
            <div>
              <h2 className="text-2xl mb-4">Disclaimer:</h2>
              <ul className="space-y-2">
                <li className="flex items-center space-x-2">
                  <span>-</span>
                  <span>Links expire after 14 days</span>
                </li>
                <li className="flex items-center space-x-2">
                  <span>-</span>
                  <span>No illegal content allowed</span>
                </li>
                <li className="flex items-center space-x-2">
                  <span>-</span>
                  <span>Use at your own risk</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default HomePage;