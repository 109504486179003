// src/services/api.js
import config from '../config';
import axios from 'axios';

// Create axios instance with default config
const axiosInstance = axios.create({
  baseURL: config.API.BASE_URL,
  timeout: 15000,
  headers: {
    'Content-Type': 'text/plain'
  },
  // Add CORS settings
  withCredentials: false,
});

// Request interceptor with detailed logging
axiosInstance.interceptors.request.use(
  (config) => {
    console.log('API Request:', {
      url: config.url,
      method: config.method,
      headers: config.headers,
      data: config.data
    });
    return config;
  },
  (error) => {
    console.error('Request interceptor error:', error);
    return Promise.reject(error);
  }
);

// Response interceptor with detailed error handling
axiosInstance.interceptors.response.use(
  (response) => {
    console.log('API Response:', {
      url: response.config.url,
      status: response.status,
      data: response.data
    });
    return response;
  },
  (error) => {
    console.error('API Error:', {
      url: error.config?.url,
      method: error.config?.method,
      status: error.response?.status,
      data: error.response?.data,
      error: error.message,
      fullError: error
    });
    return Promise.reject(error);
  }
);

const apiService = {
  url: {
    shorten: async (url, ttl = config.DEFAULTS.TTL_DAYS) => {
      try {
        console.log('Making request to backend:', `${config.API.BASE_URL}${config.API.ENDPOINTS.SHORTEN}`);
        const response = await axiosInstance.post(
          `${config.API.ENDPOINTS.SHORTEN}?username=${config.DEFAULTS.USERNAME}&t=${ttl}`,
          url,
          {
            headers: {
              'Content-Type': 'text/plain',
              'Accept': 'application/json'
            }
          }
        );
        return response.data;
      } catch (error) {
        console.error('Shorten URL error:', {
          url,
          backendUrl: config.API.BASE_URL,
          error: error.response?.data || error.message,
          status: error.response?.status
        });
        throw new Error(error.response?.data?.message || 'Failed to shorten URL');
      }
    },

    // Shorten multiple URLs
    shortenBulk: async (urls, ttls = null) => {
      try {
        let endpoint = `${config.API.ENDPOINTS.SHORTEN_BULK}?username=${config.DEFAULTS.USERNAME}`;
        if (ttls) {
          endpoint += `&t=${Array.isArray(ttls) ? `[${ttls.join(',')}]` : ttls}`;
        } else {
          endpoint += `&t=${config.DEFAULTS.TTL_DAYS}`;
        }

        const response = await axiosInstance.post(endpoint, urls, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        return response.data;
      } catch (error) {
        const errorMessage = error.response?.data?.message || 'Failed to shorten URLs in bulk';
        console.error('Shorten bulk URLs error:', {
          urls,
          error: errorMessage,
          fullError: error
        });
        throw new Error(errorMessage);
      }
    }
  },

  auth: {
    // User registration
    register: async (username, password, email) => {
      try {
        const response = await axiosInstance.post(
          config.API.ENDPOINTS.AUTH.REGISTER,
          { username, password, email },
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        );
        return response.data;
      } catch (error) {
        throw new Error(error.response?.data?.message || 'Registration failed');
      }
    },

    // User login
    login: async (username, password) => {
      try {
        const response = await axiosInstance.post(
          config.API.ENDPOINTS.AUTH.LOGIN,
          { username, password },
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        );
        const { token } = response.data;
        if (token) {
          localStorage.setItem(config.STORAGE_KEYS.AUTH_TOKEN, token);
        }
        return response.data;
      } catch (error) {
        throw new Error(error.response?.data?.message || 'Login failed');
      }
    },

    // User logout
    logout: () => {
      localStorage.removeItem(config.STORAGE_KEYS.AUTH_TOKEN);
    }
  }
};

export default apiService;