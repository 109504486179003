import React from 'react';
import { useNavigate } from 'react-router-dom';
import apiService from '../services/api';

const AuthButtons = () => {
  const navigate = useNavigate();
  // Check if user is logged in by looking for token
  const isLoggedIn = !!localStorage.getItem('auth_token');

  const handleLogout = () => {
    apiService.auth.logout();
    navigate('/login');
  };

  const handleLogin = () => {
    navigate('/login');
  };

  return (
    <div className="fixed top-4 right-4 flex gap-2">
      {isLoggedIn ? (
        <button
          onClick={handleLogout}
          className="px-4 py-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200 transition-colors"
        >
          Logout
        </button>
      ) : (
        <button
          onClick={handleLogin}
          className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors"
        >
          Login
        </button>
      )}
    </div>
  );
};

export default AuthButtons;